import styled from "styled-components";
import { Container } from "../../styles/UI";

export const Hero = styled.header`
  /* max-height: 986px; */
  width: 100%;
  background: ${(p) => p.theme.degradeAzul};
  margin-top: -180px;
  padding-top: 180px;
  padding-bottom: 120px;
  color: var(--color-white);
  box-sizing: border-box;
  overflow: hidden;

  ${Container} {
    .cta {
      position: relative;
      z-index: 10;

      h1 {
        font-size: 30px;
        font-weight: 600;
        letter-spacing: -0.01em;
        margin-bottom: 24px;
        line-height: 1.25;
      }

      p {
        font-weight: 350;
        font-size: 20px;
        line-height: 32px;
        margin-bottom: 24px;
      }
    }

    .illustration {
      z-index: 1;
      margin-top: 0px;
      position: relative;

      .bg-logo {
        position: absolute;
        z-index: 0;
        height: 800px;
        right: -180px;
        top: -240px;

        @media (min-width: ${(p) => p.theme.bp.tablet}) {
          left: 0px;
          top: -360px;
          height: 1360px;
        }
      }
    }

    @media (min-width: ${(p) => p.theme.bp.tablet}) {
      max-height: 820px;
      display: grid;
      /* grid-template-columns: repeat(2, 1fr); */
      grid-template-columns: minmax(600px, 1fr) 1fr;
      grid-column-gap: 32px;
      align-items: center;

      h1 {
        font-size: 35px;
        margin-top: 64px;
      }
    }
  }
`;

export const Baixar = styled.section`
  padding: 88px 0;
  background: ${(p) => p.theme.bgGray};

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    ${Container} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 64px;
    }
  }

  .form {
    background: ${(p) => p.theme.branco};
    border-radius: 0 20px;
    padding: 32px;
    align-self: flex-start;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.05),
      0px 16px 50px rgba(0, 0, 0, 0.15);

    h2 {
      font-weight: 600;
      font-size: 35px;
      line-height: 42px;
      letter-spacing: -0.01em;
      color: ${(p) => p.theme.primary};
      margin-bottom: 32px;
    }
  }

  .versao {
    margin-top: 24px;
    margin-right: 8px;
    text-align: right;
    font-size: 14px;
    color: ${(p) => p.theme.textGray};
  }

  .outrasVersoes {
    margin-top: 32px;
    font-size: 16px;
    color: ${(p) => p.theme.textGray};
  }

  div.tabelas {
    box-sizing: border-box;

    @media (max-width: ${(p) => p.theme.bp.tablet}) {
      margin-top: 48px;
    }

    h3 {
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      letter-spacing: -0.01em;
      color: ${(p) => p.theme.primary};
      margin-bottom: 16px;
    }

    ul {
      list-style-position: outside;
      list-style-type: disc;
      font-size: 16px;
      font-weight: 350;
      box-sizing: border-box;
      margin-bottom: 32px;
      color: ${(p) => p.theme.textDarkerGray};

      li {
        margin-left: 32px;
        line-height: 32px;

        &::marker {
          font-size: 80%;
          margin: 0;
        }
      }
    }

    table {
      width: 100%;
      margin: 0 0 2em 0;
      border-collapse: collapse;
      text-align: left;
      color: ${(p) => p.theme.textDarkerGray};

      td {
        padding: 0.75em;
      }

      th {
        padding: 0.75em;
        font-weight: 500;
      }

      tbody {
        tr {
          border-bottom: 1px solid #dfdfdf;
        }

        tr:first-child td {
          border-top: 2px solid #b3b7bd;
        }

        tr:last-child td {
          border-bottom: 2px solid #b3b7bd;
        }

        tr:nth-child(even) td {
          background-color: #fff;
        }
      }
    }
  }

  p.duvidas {
    grid-column: 1 / span 2;
    grid-row: 2;
    text-align: center;
    font-weight: 600;
    line-height: 48px;
    color: ${(p) => p.theme.textDarkerGray};
    margin-top: 40px;
  }
`;

export const Solucoes = styled.section`
  padding: 42px 0 88px;
  background: ${(p) => p.theme.branco};
  overflow: hidden;

  h5 {
    font-weight: 600;
    font-size: 30px;
    line-height: 40px;
    text-align: center;
    color: ${(p) => p.theme.primary};
    margin-bottom: 44px;
  }

  @media (min-width: ${(p) => p.theme.bp.tablet}) {
    padding: 120px 0;

    h5 {
      font-size: 35px;
      line-height: 36px;
      margin-bottom: 64px;
    }
  }
`;

export const Atualizacao = styled.div`
  padding: 32px 4px 16px;
  font-size: 18px;

  div.versoes {
    margin-top: 24px;
    display: flex;
    font-size: 16px;
    color: ${(p) => p.theme.textDarkerGray};

    .wrap {
      .line {
        display: flex;
        margin-bottom: 8px;

        p:first-child {
          margin-right: 8px;
        }

        p:last-child {
          font-weight: 600;
          margin-left: auto;
        }
      }
    }
  }
`;
