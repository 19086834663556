import React from "react";
import { graphql } from "gatsby";
import BaixarTaxsheets from "../pagesFiles/baixarTaxSheets";

export default function taxdashs({ data, location }) {
  const params = new URLSearchParams(location.search);
  const isClient = params.get("cliente");
  const versaoCliente = params.get("versao");

  return (
    <BaixarTaxsheets
      email={location.state?.email}
      isClient={isClient}
      versao={data.allVersaoTaxSheetsJson.nodes[0].versao}
      versaoAnterior={"6.14"}
      versaoCliente={versaoCliente}
    />
  );
}

export const query = graphql`
  {
    allVersaoTaxSheetsJson {
      nodes {
        versao
      }
    }
  }
`;
