import React from "react";

import { AnimateSharedLayout, motion } from "framer-motion";

import * as S from "./style";

export default function SwitchButton({
  variant,
  selection = 0,
  handleChange,
  options,
}) {
  return (
    <S.Switch $variant={variant}>
      <AnimateSharedLayout>
        {options.map((option, index) => (
          <button key={index} title={option} onClick={() => handleChange(index)}>
            <p className={selection === index ? "active" : ""}>{option}</p>
            {selection === index && (
              <motion.div
                className="active"
                layoutId="active"
                animate={{ backgroundColor: "#FFFFFF" }}
                transition={spring}
              />
            )}
          </button>
        ))}
      </AnimateSharedLayout>
    </S.Switch>
  );
}

const spring = {
  type: "spring",
  stiffness: 500,
  damping: 30,
};
